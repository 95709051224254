.heading1 {
  font-weight: 600;
  font-size: 3.375rem;
  // line-height: 3.5rem;
  margin: 0;
}
.heading2 {
  font-weight: 600;
  font-size: 2.25rem;
  // line-height: 2.4rem;
  margin: 0;
}
.heading3 {
  font-weight: 600;
  font-size: 1.5rem;
  // line-height: 2rem;
  margin: 0;
}
.bodyLg {
  font-weight: 400;
  font-size: 1rem;
  // line-height: 1.5rem;
}
.bodyLgBold {
  font-weight: 600;
  font-size: 1rem;
  // line-height: 1.5rem;
}
.bodySm {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  // line-height: 0.875rem;
}
.bodySmBold {
  font-weight: 700;
  font-size: 0.75rem;
  // line-height: 0.875rem;
}

@media screen and (max-width: 767px) {
  .heading1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    // line-height: 2.25rem;
    margin: 0;
  }
  .heading2 {
    font-weight: 700;
    font-size: 1.25rem;
    // line-height: 2rem;
    margin: 0;
  }
  .heading3 {
    font-weight: 700;
    font-size: 1rem;
    // line-height: 24px;
    margin: 0;
  }
  .bodyLg {
    font-weight: 400;
    font-size: 1rem;
    // line-height: 1.5rem;
  }
  .bodyLgBold {
    font-weight: 600;
    font-size: 1rem;
    // line-height: 1.5rem;

  }
  .bodySm {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    // line-height: 0.875rem;
  }
  .bodySmBold {
    font-weight: 700;
    font-size: 0.75rem;
    // line-height: 0.875rem;

  }
}
