body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test{
  border: 2px solid red !important;
}
.test_2{
  border: 2px solid green !important;
}
.btn_bg{
  background-color: #6D6D6D !important;
  color: #ffff !important;
}
.green-border-bottom {
  border-bottom: 2px solid #FF002F !important;
  width: 85% !important;
}
.white_space{
  white-space: pre-line !important;
}
.white_space_2{
  white-space: nowrap !important;
}

.custom-row {
  display: flex !important;
  flex-direction: row-reverse !important; /* Reverse the order of columns */
}

/* Reset the column order for medium screen sizes and above */
@media (min-width: 768px) {
  .custom-row {
    flex-direction: row;
  }
}



@media (max-width: 767px) {
  .next-text {
    height: 100px !important;
  }
}