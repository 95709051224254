// Bootstrap
@import "custom";
@import "~bootstrap/scss/bootstrap";

// @font-face {font-family: "TIMMONS NY"; src: url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.eot"); src: url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.woff") format("woff"), url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.svg#TIMMONS NY") format("svg"); }
@font-face {
    font-family: "TIMMONS NY";
    src: local("TIMMONS NY"),
        url(../../public/fonts/TIMMONS_NY_FINAL.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url(../../public/fonts/Roboto-Regular.ttf) format("truetype");
}
// CSS objects
* {
    color: "#000" !important;
}
.overlay {
    position: absolute;

    left: 50%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
@media (min-width: 768px) {
    //  .sidebag{
    //   position: fixed;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   padding: 0;
    //   margin: 0;
    //  }
    //  .sidefloor{
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   padding: 0;
    //   margin: 0;
    //  }
}

@media (max-width: 900px) {
    .next-screen-container {
        border: 1px solid #000;
        box-shadow: inset 0 0 0 2px #000;
        padding: 10px;
        // margin-bottom: 5px;
        margin-left: 10%;
    }

    .playtext-value h1 {
        font-weight: 600;
        font-size: 3.375rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .playtext-label h1 {
        font-weight: 600;
        font-size: 2.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .next-text-value h1 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .next-text-label h1 {
        font-weight: 600;
        font-size: 2rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .nested-floor .playtext-value h1,
    h2,
    h3 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }
    .nested-floor .playtext-label h1,
    h2,
    h3 {
        font-weight: 600;
        font-size: 1.775rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }
}
@media (max-width: 768px) {
    .floor-div {
        height: 50% !important;
    }
    .next-screen-container {
        border: 1px solid #000;
        box-shadow: inset 0 0 0 2px #000;
        padding: 10px;
        // margin-bottom: 5px;
        margin-left: 10%;
    }

    .floor_bag_image {
        height: 200px !important;
        width: auto;
        object-fit: contain;
    }

    .playtext-value h1 {
        font-weight: 600;
        font-size: 3.375rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .playtext-value h2 {
        font-weight: 600;
        font-size: 2.25rem !important;
        /* line-height: 2.4rem; */
        margin: 0;
    }

    .playtext-value h3 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 2rem; */
        margin: 0;
    }
    .playtext-label h1 {
        font-weight: 600;
        font-size: 2.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .playtext-label h2 {
        font-weight: 600;
        font-size: 2rem !important;
        /* line-height: 2.4rem; */
        margin: 0;
    }

    .playtext-label h3 {
        font-weight: 600;
        font-size: 1rem !important;
        /* line-height: 2rem; */
        margin: 0;
    }

    .next-text-value h1 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .next-text-value h2 {
        font-weight: 600;
        font-size: 1rem !important;
        /* line-height: 2.4rem; */
        margin: 0;
    }

    .next-text-value h3 {
        font-weight: 600;
        font-size: 0.75rem !important;
        /* line-height: 2rem; */
        margin: 0;
    }
    .next-text-label h1 {
        font-weight: 600;
        font-size: 2rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }

    .next-text-label h2 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 2.4rem; */
        margin: 0;
    }

    .next-text-label h3 {
        font-weight: 600;
        font-size: 1rem !important;
        /* line-height: 2rem; */
        margin: 0;
    }
    // .next-text{
    //   position: absolute;
    //   width: 100%;
    // }
    .nested-floor .playtext-value h1,
    h2,
    h3 {
        font-weight: 600;
        font-size: 1.5rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }
    .nested-floor .playtext-label h1,
    h2,
    h3 {
        font-weight: 600;
        font-size: 1.775rem !important;
        /* line-height: 3.5rem; */
        margin: 0;
    }
}

.overlay img {
    max-width: 100%;
    max-height: 100%;
}

body {
    background-color: rgb(2, 17, 30) !important;
    font-weight: 200;
}
.image-overlay {
    position: absolute;
    top: 0;
    right: -100%;
    width: 200px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: right 0.3s ease-in-out;
    z-index: 152200;
}

.image-overlay img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-overlay:hover {
    right: 0;
}

.vh-100 {
    height: 100vh !important;
}
.vh-80 {
    height: 80vh !important;
}
.vw-100 {
    width: 100vw !important;
}

.border-red-bottom {
    border-bottom: 1px solid #ff002f !important;
}
.border-black-bottom {
    border-bottom: 1px solid #000 !important;
}
.border-red {
    border: #ff002f !important;
}
.border-black {
    border-color: #000 !important;
}

// .w-50 {
//     width: 50vh;
// }

// thumbnail
.pointer {
    cursor: pointer;
}
.thumbnail {
    height: 80px;
    width: 80px;
    border-radius: 100%;
}

.input-transparent {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
}

.bg-red {
    background-color: #ff002f !important;
}
input:focus {
    outline: none;
}
textarea:focus {
    outline: none;
}

.text-red {
    color: #ff002f !important;
}

.bg-black {
    background-color: rgba(0, 0, 0, 0) !important;
}

.text-black {
    color: rgba(0, 0, 0, 1) !important;
}

.border-black {
    border-color: rgba(0, 0, 0, 1) !important;
}

.text-white {
    color: "white" !important;
}
